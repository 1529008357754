.faqs {
  max-width: 80%;
  background-color: var(--background-color);
  width: 70vw;
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.faqs h2 {
    color: var(--text-color);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.faq-item {
  margin-bottom: 1.5rem;
  border-radius: 8px;
  overflow: hidden;
}

.faq-question {
  font-weight: 600;
  font-size: 1.1rem;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.faq-question::after {
  content: '+';
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.faq-question.open::after {
  transform: rotate(45deg);
}

.faq-answer {
  padding: 0 1rem 1rem;
  color: var(--text-color);
  line-height: 1.6;
}

/* Light mode styles */
@media (prefers-color-scheme: light) {
  .faqs {
    background-color: #ffffff;
    color: #000000;
  }
  
  .faqs h2 {
    color: #000000;
  }
  
  .faq-item {
    border: 1px solid #e0e0e0;
  }
  
  .faq-question {
    background-color: #f5f5f5;
  }
  
  .faq-question:hover {
    background-color: #e8e8e8;
  }
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  .faqs {
    background-color: var(--background-color);
    color: #ffffff;
  }
  
  .faqs h2 {
    color: var(--text-color);
  }
  
  .faq-item {
    border: 1px solid #333333;
  }
  
  .faq-question {
    background-color: #1a1a1a;
  }
  
  .faq-question:hover {
    background-color: #2a2a2a;
  }
}
