.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    background-color: var(--background-color) !important;
    color: var(--text-color);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 20000;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.logo {
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 1px;
    transition: transform 0.3s ease;
    color: var(--text-color);
}

.logo:hover {
    transform: scale(1.05);
}

.nav-and-toggle {
    display: flex;
    align-items: center;
}

.nav {
    display: flex;
    gap: 1.5rem;
}

.nav-group {
    position: relative;
}

.group-toggle {
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    transition: background-color 0.3s ease;
}

.group-toggle:hover {
    background-color: rgba(var(--text-color-rgb), 0.1);
}

.group-toggle .rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.group-items {
    display: none;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--background-color);
    border: 1px solid rgba(var(--text-color-rgb), 0.2);
    border-radius: 8px;
    padding: 0.5rem 0;
    z-index: 10;
    min-width: 150px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease, top 0.3s ease;
}

.group-items.open {
    display: block;
    opacity: 1;
    top: 100%;
}

.group-items a {
    display: block;
    padding: 0.75rem 1.5rem;
    color: var(--text-color);
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.group-items a:hover,
.group-items a.active {
    background-color: rgba(var(--text-color-rgb), 0.1);
}

.theme-toggle,
.menu-toggle {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.theme-toggle:hover,
.menu-toggle:hover {
    background-color: rgba(var(--background-color), 0.1);
    color: var(--text-color);
}

.menu-toggle {
    display: none; /* Hide menu toggle by default */
}

@media (max-width: 768px) {
    .header {
        padding: 1rem;
    }

    .nav {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: var(--background-color);
        border-top: 1px solid rgba(var(--text-color-rgb), 0.2);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .nav.open {
        display: block;
    }

    .nav-group {
        margin: 0;
    }

    .group-toggle {
        width: 100%;
        padding: 1rem;
        justify-content: space-between;
        border-radius: 0;
    }

    .group-items {
        position: static;
        border: none;
        border-top: 1px solid rgba(var(--text-color-rgb), 0.2);
        box-shadow: none;
        opacity: 1;
        transform: none;
        left: 0;
        min-width: 100%;
    }

    .group-items a {
        padding: 1rem 1.5rem;
    }

    .menu-toggle {
        display: block; /* Show menu toggle on mobile */
        margin-left: 1rem;
    }

    .theme-toggle {
        margin-left: 0;
    }
}