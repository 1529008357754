@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root {
  --background-color: #ffffff;
  --text-color: #000000;
}

.dark-mode {
  --background-color: #000000;
  --text-color: #ffffff;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  
}