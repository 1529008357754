.content-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the start */
}

.carousel {
  flex: 2; /* Carousel takes 2/3 of the space */
  margin-right: 20px; /* Space between carousel and form */
  z-index: 1; /* Set z-index for the carousel */
  border: none; /* Remove default border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden; /* Ensure child elements respect the border radius */
}

.contact-form {
  flex: 1; /* Form takes 1/3 of the space */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid;
  border-image: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5)) 1;
}

.content-section {
  margin: 0 0; /* Space between sections */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent background */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.service-button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #007bff; /* Button color */
  color: white; /* Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.service-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.carousel-container {
  position: relative; /* Position relative for absolute positioning of children */
  width: 100%; /* Full width of the viewport */
  height: 600px; /* Fixed height for the carousel */
  overflow: hidden; /* Hide overflow to prevent scrollbars */
}

.carousel {
  width: calc(100%-'0.4rem'); /* Full width of the container */
  height: 100%; /* Full height of the container */
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.carousel .slide {
  height: 100%; /* Ensure each slide takes full height */
}

.carousel img {
  width: 100%; /* Make images responsive */
  height: 100%; /* Make images fill the carousel */
  object-fit: cover; /* Cover the entire area without distortion */
  border-radius: 0; /* No rounded corners */
}

.carousel-title {
  position: absolute; /* Position the title absolutely */
  bottom: 0; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */
  color: white; /* Text color */
  font-size: 2rem; /* Adjust font size */
  z-index: 10; /* Ensure it appears above the carousel */
  text-align: center; /* Center text */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.carousel-description {
  position: absolute; /* Position the description absolutely */
  top: calc(50% + 30px); /* Center vertically with a slight offset */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */
  color: white; /* Text color */
  font-size: 1.2rem; /* Adjust font size */
  z-index: 10; /* Ensure it appears above the carousel */
  text-align: center; /* Center text */
}

.product-section {
  background-color: var(--background); /* Use the background variable */
  color: var(--text); /* Use the text color variable */
  padding: 20px;
  margin: 20px 0; /* Space between sections */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  text-align: center; /* Center text */
}

.contact-section {
  background-color: var(--background); /* Use the background variable */
  color: var(--text); /* Use the text color variable */
  padding: 20px;
  margin: 20px 0; /* Space between sections */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.carousel img {
  width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners for images */
}

/* Base styles for larger screens */
.home {
  background-color: var(--background); /* Use the background variable */
  color: var(--text); /* Use the text color variable */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .carousel-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
  }

  .carousel {
    margin-right: 0; /* Remove margin on smaller screens */
    margin-bottom: 0; /* Add space below carousel */
  }

  .contact-form {
    width: 100%; /* Full width on smaller screens */
  }

  .product-section,
  .contact-section {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .carousel-title {
    font-size: 1.5rem; /* Smaller font size for mobile */
  }

  .carousel-description {
    font-size: 1rem; /* Smaller font size for mobile */
  }

  .service-button {
    padding: 8px 16px; /* Adjust button size for mobile */
  }
}
