.careers {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  color: var(--text-color);
}

.careers h1 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--primary-color);
}

.careers-intro {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.search-and-filter {
  margin-bottom: 3rem;
}

.search-bar {
  margin-bottom: 1.5rem;
}

.search-bar input {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.search-bar input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.filter-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.filter-btn {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 2px solid var(--border-color);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-btn.active,
.filter-btn:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
  font-weight: bold;
  border-color: var(--primary-color);
}

.job-listings {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.job-card {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.job-card h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.job-details, .job-experience {
  font-size: 1rem;
  color: var(--text-color-secondary);
  margin-bottom: 0.5rem;
}

.job-description {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.job-card h3 {
  font-size: 1.4rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.job-card ul {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.job-card li {
  margin-bottom: 0.5rem;
}

.apply-btn, .toggle-details {
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-right: 1rem;
}

.apply-btn:hover, .toggle-details:hover {
  transform: translateY(-2px);
  font-weight: bold;
}

.no-results {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 3rem;
  color: var(--text-color-secondary);
}

.application-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.application-form {
  background-color: var(--background-color);
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.application-form h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: var(--primary-color);
  text-align: center;
}

.application-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.application-form input,
.application-form textarea {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.application-form input:focus,
.application-form textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.application-form textarea {
  min-height: 150px;
  resize: vertical;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.submit-btn,
.cancel-btn {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-btn {
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
}

.submit-btn:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-2px);
}

.cancel-btn {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 2px solid var(--border-color);
}

.cancel-btn:hover {
  background-color: var(--border-color);
}

@media (max-width: 768px) {
  .careers {
    padding: 2rem 1rem;
  }

  .careers h1 {
    font-size: 2.5rem;
  }

  .job-listings {
    grid-template-columns: 1fr;
  }

  .job-card {
    padding: 1.5rem;
  }

  .job-card h2 {
    font-size: 1.6rem;
  }

  .apply-btn, .toggle-details {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }

  .application-form {
    width: 90%;
    height: 90%;
    margin: 2rem;
  }

  .submit-btn,
  .cancel-btn {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}
