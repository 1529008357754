.footer {
  position: sticky; /* Make footer sticky */
  bottom: 0; /* Stick to the bottom */
  padding: 0.3rem 0.2rem; /* Padding for spacing */
  text-align: center; /* Center text */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
  z-index: 1000; /* Ensure it stays above other content */
  p {
    margin: 1rem !important;
    font-size: 1rem;
  }
}

/* Light mode styles */
@media (prefers-color-scheme: light) {
  .footer {
    background-color: white;
    color: black;
  }
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  .footer {
    background-color: black;
    color: #f0f0f0;
  }
}
