.products {
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

.text-center {
  text-align: center;
  margin-bottom: 2rem;
}

.product-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.product-selector-btn {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--background-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.product-selector-btn:hover {
  transform: scale(1.05);
}

.product-selector-btn.active {
  background-color: var(--text-color);
  color: white;
}

.dark-mode .product-selector-btn {
  background-color: #333;
  color: #fff;
}

.dark-mode .product-selector-btn.active {
  background-color: var(--primary-color-dark);
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
}

.product-image-container {
  flex: 1;
  min-width: 300px;
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-info {
  flex: 2;
  min-width: 300px;
}

.product-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.product-description {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.product-features {
  list-style-type: none;
  padding: 0;
}

.product-features li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.feature-icon {
  margin-right: 1rem;
  color: var(--feature-icon-color);
}

.product-cta {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--background-color);
  border: 1px solid var(--text-color);
  color: var(--text-color);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  border: 1px solid var(--text-color);
  box-shadow: 0 4px 6px var(--text-color);
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold;
  font-size: 1.1rem;
  font-family: var(--font-family);
  /* text-transform: uppercase; */
  letter-spacing: 0.05em;
}

.product-cta:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .product-container {
    flex-direction: column;
  }
  
  .product-image-container,
  .product-info {
    width: 100%;
  }
}

/* Light mode styles */
@media (prefers-color-scheme: light) {
  .products h2,
  .product-title,
  .product-description,
  .product-features li {
    color: #000000;
  }

  .product-container {
    background-color: #ffffff;
  }

  .product-features li::before {
    color: #007bff;
  }

  .product-cta {
    background-color: var(--text-color);
    color: var(--background-color);
  }

  .product-cta:hover {
    transform: scale(1.05);
  }
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  .products h2,
  .product-title,
  .product-description,
  .product-features li {
    color: #ffffff;
  }

  .product-container {
    background-color: #000000;
  }

  .product-features li::before {
    color: #3a9fff;
  }

  .product-cta {
    background-color: var(--text-color);
    color: var(--background-color);
  }

  .product-cta:hover {
    transform: scale(1.05);
  }
}
