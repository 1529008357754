.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 20px;
  min-width: 80%;
  width: 70rem;
  max-width: 80%;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.contact h2 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
  letter-spacing: -1px;
  color: var(--text-color);
}

.contact-description {
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 80%;
  color: var(--text-color);
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 25px;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--text-color);
}

.input-icon {
  position: absolute;
  left: 15px;
  top: 42px;
  font-size: 1.1rem;
  color: var(--text-color);
}

.form-group input,
.form-group textarea {
  width: calc(100% - 60px);
  padding: 15px 15px 15px 45px;
  border: 2px solid var(--text-color);
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  transition: border-color 0.3s ease;
  border-radius: 8px;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--text-color);
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-btn {
  align-self: stretch;
  padding: 15px 24px;
  background-color: var(--text-color);
  color: var(--background-color);
  border: none;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
}

.submit-btn:hover {
  transform: translateY(-2px);
}

.btn-icon {
  margin-right: 10px;
  font-size: 1.1rem;
}

.submit-message {
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
}

.submit-message.success {
  color: var(--text-color);
}

.submit-message.error {
  color: var(--text-color);
}

.submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .contact {
    padding: 60px 15px;
  }

  .contact h2 {
    font-size: 2.4rem;
  }

  .form-group input,
  .form-group textarea {
    padding: 12px 12px 12px 40px;
  }

  .input-icon {
    top: 38px;
  }
}

.contact-info {
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
}

.contact-info strong {
  margin-right: 10px;
}