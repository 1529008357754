.services {
  padding: 4rem 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: var(--text-color);
  background-color: var(--background-color);
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for dark/light mode */
}

.services h2 {
  font-size: 3.2rem;
  margin-bottom: 60px;
  text-align: center;
  font-weight: 800;
  letter-spacing: -1px;
  background: var(--text-color);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; /* Fallback for browsers that don't support background-clip: text */
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding: 30px;
}

.service-item {
  background: linear-gradient(135deg, var(--item-bg-start), var(--item-bg-end));
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-content {
  padding: 30px;
}

.service-icon-wrapper {
  width: 70px;
  height: 70px;
  background: var(--accent-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.service-icon {
  font-size: 1.8rem;
  color: var(--icon-color);
}

.service-item h3 {
  font-size: 1.6rem;
  margin-bottom: 15px;
  color: var(--title-color);
}

.service-item p {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-color);
}

.learn-more-btn {
  background-color: var(--text-color);
  color: var(--background-color);
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px; /* Rounded corners for buttons */
}

.btn-icon {
  transition: transform 0.3s ease;
}

.service-item:hover,
.service-item.hovered {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.service-item:hover .service-icon-wrapper,
.service-item.hovered .service-icon-wrapper {
  transform: scale(1.1) rotate(5deg);
}

.service-item:hover .btn-icon,
.service-item.hovered .btn-icon {
  transform: translateX(5px);
}

@media (max-width: 1200px) {
  .service-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services {
    padding: 3rem 20px;
  }

  .services h2 {
    font-size: 2.8rem;
    margin-bottom: 50px;
  }

  .service-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .service-item {
    padding: 30px;
  }

  .service-icon-wrapper {
    width: 80px;
    height: 80px;
  }

  .service-icon {
    font-size: 2rem;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 30px;
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.modal p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}
