.policy-container {
  max-width: 80%;
  width: 80vw;
  margin: 0 auto;
  padding: 2.5rem;
  line-height: 1.8;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  color: var(--text-color);
  background-color: var(--background-color);
}

.policy-container h1 {
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
  text-align: center;
  border-bottom: 3px solid;
  padding-bottom: 0.8rem;
  letter-spacing: 1px;
}

.policy-container h2 {
  font-size: 2rem;
  margin-top: 2.5rem;
  margin-bottom: 1.2rem;
  border-left: 5px solid;
  padding-left: 1.2rem;
  letter-spacing: 0.5px;
}

.policy-container p {
  margin-bottom: 1.2rem;
  text-align: justify;
}

.policy-container ul {
  margin-left: 2.5rem;
  margin-bottom: 1.2rem;
}

.policy-container li {
  margin-bottom: 0.8rem;
  position: relative;
}

.policy-container li::before {
  content: '•';
  position: absolute;
  left: -1.2rem;
  font-size: 1.2em;
}

.policy-container section {
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.policy-container h1,
.policy-container h2,
.policy-container li::before {
  color: var(--text-color);
}

/* Responsive design */
@media (max-width: 768px) {
  .policy-container {
    margin: 1.5rem;
    padding: 1.8rem;
  }

  .policy-container h1 {
    font-size: 2.2rem;
  }

  .policy-container h2 {
    font-size: 1.7rem;
  }
}

/* Print styles */
@media print {
  .policy-container {
    box-shadow: none;
    margin: 0;
    padding: 0;
    color: #000000;
    background-color: #ffffff;
  }

  .policy-container h1,
  .policy-container h2 {
    color: #000000;
  }

  .policy-container section {
    background-color: transparent;
    padding: 0;
  }
}