body {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the body takes full height */
}

.App {
  flex: 1; /* Allow the App to grow and fill the space */
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  position: sticky; /* Fix the header at the top */
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  background-color: var(--background-color); /* Match your header color */
  color: white;
  z-index: 1000; /* Ensure it stays above other content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
  padding-top: 0;
  padding-bottom: 0.5rem;
}

.footer {
  position: sticky; /* Make footer sticky */
  bottom: 0; /* Stick to the bottom */
  background-color: white; /* Background color */
  color: black; /* Text color */
  /*padding: 0.3rem 0.2rem; /* Padding for spacing */
  text-align: center; /* Center text */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
  z-index: 1000; /* Ensure it stays above other content */
}

.home {
  flex: 1; /* Allow home to take available space */
  background-color: var(--background);
  color: var(--text);
  overflow-y: auto; /* Allow scrolling */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

.app {
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.dark-mode {
  background-color: black;
  color: #fff;
}

.light-mode {
  background-color: #fff;
  color: #000;
}

.toggle-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  font-size: 1.2rem;
  padding: 8px;
  padding-left: 30px;
  border-radius: 50%;
  transition: var(--transition);
}

.theme-toggle:hover {
  background-color: rgba(var(--background-color), 0.1);
  color: var(--text-color);
  font-weight: 700;
}
.logo {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: var(--text-color);
  letter-spacing: 1px;
}