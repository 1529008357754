.tbn247-container {
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.tbn247-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.features-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature-item {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}

.feature-item svg {
  margin-right: 0.5rem;
}
.tbn247-links {
    display: flex;
    flex-direction: row;
    gap: 1rem;

}

.tbn247-link {
  width: 100px;
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--text-color);
  color: var(--background-color);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  &:hover {
    transform: translateY(-5px) scale(1.05);
  }
}

.tbn247-link:hover {
    background-color: var(--text-color);
    color: var(--background-color);
}

.release-notes {
  margin-top: 2rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.release-item {
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  border: 1px solid var(--text-color);
  border-radius: 8px;
  background-color: var(--background-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.release-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.release-item h3 {
  margin-bottom: 0.75rem;
  font-size: 1.3rem;
  color: var(--text-color);
  font-weight: bold;
}

.release-item ul {
  list-style-type: disc;
  padding-left: 1.75rem;
}

.release-item li {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: var(--text-color);
  line-height: 1.5;
}

.release-actions {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.release-button {
  padding: 0.6rem 1.2rem;
  background-color: var(--text-color);
  color: var(--background-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: 600;
}

.release-button:hover {
  background-color: var(--text-color);
  color: var(--background-color);
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: var(--background-color);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}

.modal-content .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
}

.tbn247-container {
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.tbn247-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
}

.release-notes {
  margin-top: 2rem;
}

.release-item {
  margin-bottom: 2rem;
}

.release-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  position: relative;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  margin-top: 1rem;
}

.modal-body h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.modal-body p {
  margin-bottom: 1rem;
}

.modal-body ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.modal-body ul li {
  margin-bottom: 0.5rem;
}

.modal-body a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: 600;
}

.modal-body a:hover {
  text-decoration: underline;
}
