.about-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 60px 30px;
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: 1.6;
}

h1 {
  color: var(--text-color);
  border-bottom: 3px solid var(--text-color);
  padding-bottom: 20px;
  margin-bottom: 40px;
  font-size: 3em;
  text-align: center;
  letter-spacing: 1px;
}

h2 {
  color: var(--text-color);
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 2em;
  border-left: 4px solid var(--text-color);
  padding-left: 15px;
}

p {
  line-height: 1.8;
  margin-bottom: 20px;
  font-size: 1.1em;
}

.introduction p:first-of-type {
  font-size: 1.3em;
  font-weight: 300;
  margin-bottom: 30px;
  text-align: center;
  font-style: italic;
  color: var(--text-color);
  opacity: 0.9;
}

.company-values ul {
  list-style-type: none;
  padding-left: 0;
}

.company-values li {
  margin-bottom: 20px;
  padding-left: 30px;
  position: relative;
}

.company-values li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--text-color);
  font-size: 1.5em;
}

.company-values li strong {
  display: block;
  font-size: 1.2em;
  margin-bottom: 5px;
  color: var(--text-color);
}

section {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 30px;
  border-radius: 12px;
  margin-bottom: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

section:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.integrity-focus, .conclusion {
  background-color: rgba(var(--text-color-rgb), 0.1);
  border-left: 4px solid var(--text-color);
}

@media (max-width: 768px) {
  .about-container {
    padding: 40px 20px;
  }

  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 1.8em;
  }

  p, .company-values li {
    font-size: 1em;
  }
}
