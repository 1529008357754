body {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.blog-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
}

h1 {
  text-align: center;
  color: var(--text-color);
  font-size: 2.5em;
  margin-bottom: 40px;
  position: relative;
}

h1::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: var(--text-color);
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.blog-post {
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-post:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.blog-post h2 {
  margin: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--text-color);
  font-size: 1.4em;
}

.post-content {
  padding: 20px;
}

.post-date {
  font-size: 0.9em;
  color: var(--text-color);
  opacity: 0.7;
  margin-bottom: 10px;
}

.post-excerpt {
  margin-bottom: 20px;
  color: var(--text-color);
}

.read-more {
  display: inline-block;
  background-color: var(--text-color);
  color: var(--background-color);
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  transition: opacity 0.3s ease;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9em;
}

.read-more:hover {
  opacity: 0.8;
}

.blog-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-input, .category-select {
  padding: 8px;
  border: 1px solid var(--text-color);
  border-radius: 4px;
  font-size: 1em;
}

.search-input {
  flex-grow: 1;
  margin-right: 10px;
}

.category-select {
  min-width: 120px;
}

.no-results {
  text-align: center;
  font-style: italic;
  color: var(--text-color);
  opacity: 0.7;
}

@media (max-width: 768px) {
  .blog-list {
    grid-template-columns: 1fr;
  }
  
  .blog-controls {
    flex-direction: column;
  }

  .search-input, .category-select {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-input {
    margin-right: 0;
  }
}

/* New styles for modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
}

.close-modal {
  display: block;
  margin-top: 20px;
  padding: 8px 16px;
  background-color: var(--text-color);
  color: var(--background-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.close-modal:hover {
  opacity: 0.8;
}

.full-content {
  margin-top: 20px;
  line-height: 1.6;
}
