
.event-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: var(--text-color);
  background-color: var(--background-color);
}

.event-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
  color: var(--text-color);
}

.event-date {
  font-style: italic;
  color: var(--text-color);
  margin-bottom: 15px;
  opacity: 0.7;
}

.event-date::before, .event-location::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(var(--icon-invert, 0));
}

.event-date::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="var(--text-color)"><path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"/></svg>');
  box-shadow: 0 0 0 1px var(--text-color);
  background-color: whitesmoke;
}

.event-location::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="var(--text-color)"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>');
  box-shadow: 0 0 0 1px var(--text-color);
  background-color: whitesmoke;
}
.event-description {
  line-height: 1.6;
  margin-bottom: 25px;
  color: var(--text-color);
}

.events-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
  color: var(--text-color);
  background-color: var(--background-color);
}

.event-card {
  border: 1px solid var(--text-color);
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(var(--text-color), 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(var(--text-color), 0.15);
}

.event-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
}

.event-date, .event-location {
  color: var(--text-color);
  display: flex;
  align-items: center;
  opacity: 0.7;
}

.event-description {
  line-height: 1.6;
  margin-bottom: 25px;
  color: var(--text-color);
}

.event-speakers h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--text-color);
}

.speaker {
  margin-bottom: 15px;
  padding: 15px;
  background-color: var(--background-color);
  border: 1px solid var(--text-color);
  border-radius: 8px;
}

.speaker-name {
  font-weight: bold;
  margin-bottom: 5px;
}

button {
  background-color: var(--text-color);
  color: var(--background-color);
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: opacity 0.3s ease;
}

button:hover {
  opacity: 0.8;
}

.speaker-bio {
  margin-top: 10px;
  font-style: italic;
  color: var(--text-color);
  opacity: 0.7;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .event-meta {
    flex-direction: column;
  }
  
  .event-date, .event-location {
    margin-bottom: 10px;
  }
}