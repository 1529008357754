.directory {
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

.directory h1 {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 3.5rem;
  font-weight: 800;
  letter-spacing: -1px;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.directory h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, var(--text-color) 0%, transparent 100%);
}

.team-members {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 3rem;
}

.member-card {
  background-color: var(--background-color);
  border-radius: 24px;
  padding: 3rem 2rem;
  box-shadow: 0 15px 35px rgba(var(--text-color), 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  position: relative;
  overflow: hidden;
}

.member-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(135deg, rgba(var(--text-color), 0.1) 0%, transparent 100%);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.member-card:hover {
  transform: translateY(-20px);
  box-shadow: 0 25px 50px rgba(var(--text-color), 0.15);
}

.member-card:hover::before {
  opacity: 1;
}

.member-avatar,
.member-avatar-placeholder {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 2.5rem;
  border: 8px solid rgba(var(--text-color), 0.1);
  transition: all 0.5s ease-in-out;
  box-shadow: 0 10px 25px rgba(var(--text-color), 0.2);
}

.member-card:hover .member-avatar,
.member-card:hover .member-avatar-placeholder {
  transform: scale(1.1) rotate(5deg);
  border-color: rgba(var(--text-color), 0.3);
}

.member-avatar-placeholder {
  background-color: rgba(var(--text-color), 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: bold;
  color: var(--text-color);
}

.member-info h2 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-size: 2.2rem;
  font-weight: 700;
  transition: color 0.3s ease;
}

.member-info h3 {
  margin-top: 0;
  margin-bottom: 1.75rem;
  opacity: 0.8;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.member-info p {
  font-size: 1.1rem;
  line-height: 1.8;
  opacity: 0.9;
  max-width: 90%;
  margin: 0 auto;
  transition: opacity 0.3s ease;
  position: relative;
  padding-bottom: 2rem;
}

.member-card:hover .member-info p {
  opacity: 1;
}

@media (max-width: 768px) {
  .directory h1 {
    font-size: 3rem;
  }

  .team-members {
    grid-template-columns: 1fr;
  }

  .member-card {
    padding: 2.5rem 1.5rem;
  }

  .member-avatar,
  .member-avatar-placeholder {
    width: 200px;
    height: 200px;
  }

  .member-info h2 {
    font-size: 2rem;
  }

  .member-info h3 {
    font-size: 1.3rem;
  }

  .member-info p {
    font-size: 1rem;
  }
}

.read-more-btn {
  background: none;
  border: none;
  color: var(--text-color);
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s ease;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.read-more-btn:hover {
  opacity: 0.7;
}